import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { StyledTabsList, StyledTab, StyledTabSelected } from "../styledComponents/tabs"
import { regular14, regular16, regular18, regular20, regular22 } from "./typography";

const SectionAV = styled.section`
  ${baseGridStyles}
  background: ${props => props.background ? props.background : 'transparent'};
  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: ${props => props.background ? '100%' : 'auto'};
  }
  section {
    padding: 0;
  }
  p{
    margin: 0;
  }

  .program-excerpt {
    p{
      margin-bottom: 1.6rem;
    }
  }
  p.excerpt: first-of-type {
    margin-top: 1.6rem;
  }

  p.download-links {
    a {
        text-decoration: none;
        border-bottom: 0;
        ${regular14}
    }
    svg 
        {
        width: 2rem;
        margin-right: 0.4rem;
        position: relative;
        top: 0.2rem;
        }
    }
  &.av-program {
    ${baseGridStyles}
    list-style: none;
    margin: 0;

    .accordion .accordion__item h2:first-of-type {
        margin-top: -47px;
    }
    .react-tabs {
      grid-column: span 4;
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
      }

      h2 {
        margin-bottom: 1.2rem;
      }
    }
    .react-tabs__tab-panel {
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
      }
    }

    .react-tabs__tab-list {
      border-bottom: 1px solid var(--light-purple-darker);
      ${StyledTabsList}
      position: relative;
      margin-bottom: 1.6rem;
      margin-top: 0;
      grid-column: span 12;
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        margin-bottom: 1.6rem;
        justify-content: center;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      
      grid-column: span 12;
      &.product-list {
        justify-content: flex-start;
      }
    }
    .react-tabs__tab {
      ${StyledTab}
      margin-left:-0.8rem;
    }
    
    .react-tabs__tab--selected {
      ${StyledTabSelected}
    }


    .AccordionItemPanel {
    
      padding: 0;
      }
  }

`
const ProgramItemTitle = styled.h2`
    display: grid;
    text-wrap-mode: wrap;
    padding-right: 3rem;
    padding-left: 5rem;
    ${regular16}
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular20}
    }
    svg {
        width: 2rem;
        position: relative;
        top: 0.3rem;
        height: 2rem;

        &.audio {
                fill: var(--light-purple-darker);
            }
        &.video {
            margin-right: 1rem;
            fill: var(--light-purple-darker);
        }
        &.pdf {
            margin-right: 1rem;
            fill: var(--light-purple-darker);
        }
    }
    
    span{
        width: 7rem; 
        background: var(--white);

        svg {
            width: 2rem;
            position: relative;
            top: 0.5rem;

            
        }
        

        @media screen and (min-width: ${breakpoints.sm}px) {
            position: absolute;
            right: 0;
            top: 1.2rem;
        }
    }
    
    span.subtitle {
        display: block;
        ${regular14}
        color: var(--light-purple-darker);
         @media screen and (min-width: ${breakpoints.sm}px) {
            position: relative¡important;
            left: 0;
            top: 0;
            width: auto;
        }
    }  
`

const ProgramItemCategoryTitle = styled.h2`
    display: inline;
    ${regular16}
    font-weight: 700;
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular20}
      font-weight: 700;
      }
    
`

const ProgramItemCategoryDescription = styled.div`
    p{
      margin-bottom: 1.6rem;
    }
`

const ProgramText = styled.div`

  p{
    margin-top: 1.6rem;
  }
  
  p:first-of-type{
    margin-top: 0;
  }
  a {
    ${regular16}
    color: var(--light-purple-darker);
    text-decoration: none;
    border-bottom: 1px solid var(--light-purple-darker);
  }

  ol {
  margin-top: 0;
  }
`
const ProgramItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
 `

 const ProgramItemListItem = styled.li`
    position: relative;
    .accordion {
      margin-top: 0;

      .accordion__button:before{
        display: none;
      }
      .accordion__button.audio-module-item{
        padding: 1rem 0 1.2rem ${props => props.hasIcon ? '2.6rem' : '0'};
      }
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        //  margin-bottom: 3rem;
    }
 `

const ProgramItemFileTitle = styled.h3`
    display: grid;
    text-wrap-mode: wrap;
    ${regular16}
    margin: 0;
    color: var(--light-purple-darker);
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-right: 8rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular20}
    }
    svg {
        width: 2rem;
        position: absolute;
        left: 0px;
        top: 1.1rem;
        height: 2rem;

        &.audio {
                fill: var(--light-purple-darker);
            }
        &.video {
            margin-right: 1rem;
            fill: var(--light-purple-darker);
        }
        &.pdf {
            fill: var(--light-purple-darker);
        }
    }

    .duration {
      top: 0;
    }
    
    span{
        width: 7rem; 
        background: var(--white);

        svg {
            width: 2rem;
            position: relative;
            top: 0.5rem;

            
        }
        

        @media screen and (min-width: ${breakpoints.sm}px) {
            position: absolute;
            right: 0;
            top: 1.2rem;
        }
    }
`

const ProgramItemFileDescription = styled.div`
    p{
      margin-bottom: 1.6rem;
      margin-left: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      p{
        margin-left: 0!important;
      }
    }
`

export {
  SectionAV,
  ProgramItemTitle,
  ProgramItemCategoryTitle,
  ProgramItemCategoryDescription,
  ProgramText,
  ProgramItemList,
  ProgramItemListItem,
  ProgramItemFileTitle,
  ProgramItemFileDescription
}