import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LayoutNoStripe from "../../../components/layoutNoStripe";
import { graphql } from "gatsby";
import { SectionAV } from "../../../styledComponents/ProgramAVContent";
import { Spinner, SpinnerContainer } from "../../../styledComponents/spinner";
import CopyContent from "./copy";
import { Section } from "../../../styledComponents/section";
import { EventListSection } from "../../../styledComponents/eventList";
import ProductList from "../../../components/ProductListPurchased"


const ProgramDivineReversalCodes = ({ data, location }) => {
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  let allproducts = data.allWpProducts.edges

  useEffect(() => {
    const fetchRoles = async () => {
      if (!isAuthenticated) {
        setRoles([]);
        setTimeout(() => setLoadingRoles(false), 3000);
        return;
      }

      try {
        const userRoles = user?.["https://www.karinagrant.co.uk/roles"] || [];
        if (userRoles.length > 0) {
          setRoles(userRoles);
        } else {
          const token = await getAccessTokenSilently({ ignoreCache: true });
          const decodedToken = JSON.parse(atob(token.split(".")[1]));
          const tokenRoles = decodedToken["https://www.karinagrant.co.uk/roles"] || [];
          setRoles(tokenRoles);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        setRoles([]);
      } finally {
        setTimeout(() => setLoadingRoles(false), 3000);
      }
    };

    fetchRoles();
  }, [isAuthenticated, user, getAccessTokenSilently]);



  // Extract the 'section' query parameter from the URL
  const params = new URLSearchParams(location.search);
  const section = params.get("section"); // e.g., 'video2'
  return (
    <>

      {loadingRoles ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          <LayoutNoStripe displayNavBorder isLander location>
            <CopyContent section={section} />
          </LayoutNoStripe>
        </>
      )}
    </>
  );
};

export default ProgramDivineReversalCodes;

export const pageQuery = graphql`
  query WordPressDivineReversalCodes {
    allWpProducts: allWpProduct(
      sort: { order: ASC, fields: menuOrder }
      filter: { title: { ne: "Permission Bonus" } }
    ) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

